import { SectionContent } from "components/common/sectionContent";
import Transition from "components/controls/transition";
import Visible from "components/controls/visible";
import { SecurityDetailCard } from "./types";
import { ReactComponent as IconFlag } from "../../images/icons/icon-flag.svg";
import { ReactComponent as IconControls } from "../../images/icons/icon-controls.svg";
import { ReactComponent as IconStack } from "../../images/icons/icon-stack.svg";
import { ReactComponent as IconBgs } from "../../images/icons/icon-bgs.svg";
import { ReactComponent as IconTrust } from "../../images/graphics/graphic-trust.svg";

const PlatformDetailCard = (detail: SecurityDetailCard) => (
  <div className="platform-detail-card sec-bg-blue-dark">
    <div>{detail.icon}</div>
    <p className="margin-none">{detail.text}</p>
  </div>
);

const TrustPlatformSection = () => {
  const detailCards: SecurityDetailCard[] = [
    {
      delay: 500,
      animation: "fadeInLeft",
      icon: <IconFlag />,
      text: "We have the expertise and automated controls necessary to assure our clients that we meet the trust services criteria related to the CIA triad.",
    },
    {
      delay: 1000,
      animation: "fadeInRight",
      icon: <IconControls />,
      text: "Flueid Decision and Flueid Transact utilize a dedicated tenancy framework to ensure data is physically and logically separated between clients.",
    },
    {
      delay: 500,
      animation: "fadeInLeft",
      icon: <IconStack />,
      text: "Flueid Decision and Flueid Transact use the most current and accurate data sources to identify and flag risks, ensuring data is delivered only to the correct party, thereby limiting unnecessary data exposure.",
    },
    {
      delay: 1000,
      animation: "fadeInRight",
      icon: <IconBgs />,
      text: "Flueid Decision is approved by leading national title underwriters, ensuring confidence in our title risk analyses.",
    },
  ];

  return (
    <section className="sec-trust-platform-section">
      <SectionContent maxWidthType="section-maxWidth-xsm">
        <h3>
          <b>Trust in</b> Our Platforms
        </h3>
        <p className="p-1 p-max-width margin-bottom_md">
          <b>
            Our VOT solutions are built on the CIA triad, ensuring data confidentiality by protecting sensitive
            information, integrity by maintaining data accuracy, and availability by providing continuous access to
            authorized users.
          </b>{" "}
          This foundation is crucial for safeguarding data, building trust with stakeholders, and ensuring seamless
          operational efficiency.
        </p>
        <div className="platform-detail-cards-grid">
          {detailCards.map((card) => (
            <Visible key={card.text}>
              {({ visible, ref }) => (
                <Transition animation={card.animation} duration={1000} visible={visible} delay={card.delay}>
                  <div ref={ref} className="platform-detail-card-transition">
                    <PlatformDetailCard {...card} />
                  </div>
                </Transition>
              )}
            </Visible>
          ))}
        </div>
      </SectionContent>
      <IconTrust className="icon-trust" />
    </section>
  );
};

export default TrustPlatformSection;
