import { SectionContent } from "components/common/sectionContent";
import styles from "./styles.module.scss";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Transition from "components/controls/transition";
import { ReactComponent as ArrowRight } from "images/icons/glyph-arrow.right.svg";
import Slider from "react-slick";
import DashedLineGroup, { DashedLine } from "components/controls/dashedLines";

type UserTypeSlide = { name: string; items: React.ReactNode[] };

export function HowItWorksSection() {
  const userTypeSlides: UserTypeSlide[] = [
    {
      name: "Real Estate Agent",
      items: [
        <>
          <b>Flueid Pro:</b> Conduct property research, unlock property profile information, manage farming and sales
          initiatives.
        </>,
        <>
          <b>Flueid Decision:</b> At listing, request a TitleCheck and collaborate with a Fueled by Flueid title
          provider to Close Certify a property for the most seamless, cost-effective transaction experience.
        </>,
      ],
    },
    {
      name: "Mortgage Lenders",
      items: [
        <>
          <b>Flueid Pro:</b> Find properties across the nation, analyze property information for existing clients, find
          new clients with our farming tools.
        </>,
        <>
          <b>Flueid Decision:</b> Run a TitleCheck to identify and preemptively address title issues at loan
          application.
        </>,
        <>
          <b>Flueid Transact:</b> Seamlessly connect with title providers to align and exchange data, accelerating the
          overall closing process.
        </>,
      ],
    },
    {
      name: "Mortgage Servicers",
      items: [
        <>
          <b>Flueid Decision for Portfolio Retention:</b> Utilize TitleCheck to regularly monitor loan health and work
          with Fueled by Flueid title providers to keep title ready and clear for the secondary market or other loan
          programs.
        </>,
        <>
          <b>Flueid Decision for Loss Mitigation:</b> Use TitleCheck to customize borrower strategies, verifying title
          and providing evidence of search results.
        </>,
        <>
          <b>Flueid Transact:</b> Optimize workflow paths with title providers through seamless systems and data
          integration.
        </>,
      ],
    },
    {
      name: "Title Underwriters ",
      items: [
        <>
          <b>Flueid Pro:</b> Offer leading tools to your title agent network for property research and farming, and an
          interface to easily order services like Flueid Decision.
        </>,
        <>
          <b>Flueid Decision:</b> Based on your unique underwriting guidelines, rapidly deliver a TitleDecision to your
          agent network for a modern, tech-based competitive advantage.
        </>,
        <>
          <b>Flueid Transact:</b> Easily deliver data, TitleDecisions, and documents to your agent network in support of
          streamlined closings.
        </>,
      ],
    },
    {
      name: "Title Agents",
      items: [
        <>
          <b>Flueid Pro:</b> Provide leading tools for property research, farming, and title service ordering to your
          network.
        </>,
        <>
          <b>Flueid Decision:</b> Digitize and automate title searches with an underwriter-approved TitleDecision to
          quickly produce and deliver clean title commitments or title condition reports.
        </>,
        <>
          <b>Flueid Transact:</b> Seamlessly deliver data and documents to your lender partners and accelerate closings.
        </>,
      ],
    },
    {
      name: "Consumers",
      items: [
        <>
          Consumers directly benefit from a seamless home buying and selling experience, a faster refinance or home
          equity closing, and a more transparent servicing process.
        </>,
      ],
    },
  ];

  const [activeSlide, setActiveSlide] = useState<UserTypeSlide>(userTypeSlides[0]);

  return (
    <>
      <section className={styles.section}>
        <SectionContent maxWidthType="section-maxWidth-small">
          <article className={styles.card}>
            <div className={styles.card_content}>
              <h2 className={styles.header}>How does VOT work for you?</h2>
              <div className={styles.content_desktop}>
                <div className={styles.buttons}>
                  {userTypeSlides.map((x) => (
                    <button
                      key={`user-type-${x.name}`}
                      className={styles.button}
                      onClick={() => setActiveSlide(x)}
                      data-active={x.name === activeSlide.name}
                    >
                      {x.name}
                    </button>
                  ))}
                </div>
                <div className={styles.slides}>
                  <Transition key={activeSlide.name} animation="fadeIn" duration={1000}>
                    <div className={styles.slide}>
                      <h3 className={styles.header_bold}>{activeSlide.name}</h3>
                      <ul className={styles.list}>
                        {activeSlide.items.map((x, i) => (
                          <li key={`desktop-item-${i}`}>
                            <p>{x}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Transition>
                  <NavLink to="/demo?topic=demo" className={styles.demo_link}>
                    <button className="btn btn-blue flex flex-center btn-learn-more btn-maxwidth ">
                      Schedule a Meeting
                    </button>
                  </NavLink>
                </div>
              </div>
              <div className={styles.content_mobile}>
                <Slider dots draggable swipe arrows>
                  {userTypeSlides.map((x) => (
                    <div key={`user-type-mobile-${x.name}`}>
                      <h3 className={styles.header_bold}>{x.name}</h3>
                      <ul className={styles.list}>
                        {x.items.map((item, i) => (
                          <li key={`mobile-item-${i}`}>
                            <p>{item}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </Slider>
                <NavLink to="/demo?topic=demo" className={styles.demo_link_mobile}>
                  <button className="btn btn-blue flex flex-center btn-learn-more btn-maxwidth ">
                    Schedule a Meeting
                  </button>
                </NavLink>
              </div>
            </div>
          </article>
        </SectionContent>
      </section>
    </>
  );
}
