import { SectionContent } from "components/common/sectionContent";
import styles from "./styles.module.scss";
import Visible from "components/controls/visible";
import Transition from "components/controls/transition";
import { ReactComponent as IconTransactGraph } from "../../../images/icons/icon-transact-graph.svg";
import { ReactComponent as IconMonitorSmartphone } from "../../../images/icons/icon-monitor-smartphone.svg";
import { ReactComponent as IconSettingsCode } from "../../../images/icons/icon-settings-code.svg";
import { ReactComponent as IconCheckDecision } from "../../../images/icons/icon-check-decision.svg";

export function VotSolutionsSection() {
  const votSolutions = [
    {
      header: "Flueid Pro",
      subheader: "Pre-transaction VOT",
      description:
        "Our dynamic app for property research, farming, and lead generation offers powerful property insights to support potential transactions. It features open order interface for services, such as pre-transaction title risk decisions from Flueid Decision and title insurance and closing services.",
      icon: <IconMonitorSmartphone width={50} />,
      link: (
        <a className={styles.link} target="_blank" href="https://pro.flueid.com/" rel="noreferrer">
          <u>Learn more at pro.flueid.com</u>
        </a>
      ),
    },
    {
      header: "Flueid Decision",
      subheader: "Early Workflow VOT",
      icon: <IconCheckDecision width={50} />,
      description:
        "Our patented platform delivers title insurer-approved risk decisions and analytics in under a minute via a TitleCheck® or TitleDecision. This accelerates service providers’ workflows by identifying transactions eligible for a “fast” process upfront, enhancing loan manufacturing while automating title search, examination, and insurance underwriting.",
    },
    {
      header: "Flueid Transact",
      subheader: "VOT at every stage",
      description:
        "Our intelligent data exchange and advanced middleware eliminates process gaps and enhances communication across the ecosystem. It delivers Flueid Decision products and other key data elements into systems of record, accelerating the overall closing process.",
      icon: <IconTransactGraph width={50} />,
    },
    {
      header: "Flueid Built ",
      subheader: "VOT in every transaction",
      description:
        "Our premier managed service operationalizes title companies with a focus on security, standardization, and technology. It features TPaaS (Title Production as a Service), providing essential title production services for transactions supported by our VOT platforms.",
      icon: <IconSettingsCode width={50} />,
    },
  ];

  return (
    <section className={styles.section} id="vot-solutions-section">
      <SectionContent maxWidthType="section-maxWidth-xsm">
        <h2>Our proven platforms fueling VOT</h2>
        <p>
          Our VOT solutions work together to find and accelerate transactions with a “clear title.” This allows title
          service providers to focus resources on complex transactions with significant property and consumer risks,
          leading to efficient resolutions.
        </p>
        <hr className={styles.hr_gradient} />
        <p className={styles.clarify_header}>
          <i>What do we mean by clear title?</i>
        </p>
        <p className="margin-top_none">
          “Clear Title” means a property has minimal or manageable title issues, which Fueled by Flueid® insurers are
          comfortable underwriting. These transactions can move through the insurable title and closing process
          efficiently, fueled by our suite of VOT technologies.
        </p>
        <div className={styles.cards_wrapper}>
          <div className={styles.outlines}>
            {votSolutions.map((x, i) => (
              <Visible key={`vot-outline-${x.header}`}>
                {(state) => (
                  <Transition visible={state.visible} duration={2000} delay={i * 500} animation="fadeInRight">
                    <article ref={state.ref} className={styles.outline}></article>
                  </Transition>
                )}
              </Visible>
            ))}
          </div>
          <div className={styles.cards}>
            {votSolutions.map((x, i) => (
              <Visible key={`vot-solution-${x.header}`}>
                {(state) => (
                  <Transition visible={state.visible} duration={2000} delay={i * 500} animation="fadeInLeft">
                    <article className={styles.card} ref={state.ref}>
                      <div className={styles.card_headers}>
                        <div className={styles.card_header_icon}>
                          {x.icon}
                          <h3 className={styles.card_header}>{x.header}</h3>
                        </div>
                        <h3 className={styles.card_subheader}>{x.subheader}</h3>
                      </div>
                      <p>{x.description}</p>
                      {x.link}
                    </article>
                  </Transition>
                )}
              </Visible>
            ))}
          </div>
        </div>
      </SectionContent>
    </section>
  );
}
