import { Link } from "react-router-dom";
import { SectionContent } from "../common/sectionContent";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";

export const CoreValues = () => (
  <section className="coreValues-section">
    <SectionContent>
      <DashedLineGroup className="dashedLineGroup-1">
        {(visible) => (
          <>
            <DashedLine width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
            <DashedLine width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
            <DashedLine width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
            <DashedLine width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
          </>
        )}
      </DashedLineGroup>
      <div className="border-fade">
        <h2>The promise behind our name</h2>
        <div className="p-2">
          We aim to make real estate transactions flow smoothly from start to finish with our comprehensive VOT
          solutions.
        </div>
      </div>
    </SectionContent>
    <div className="img-numbers" />
    <SectionContent className="numbers-marginTop">
      <div className="border-fade border-fade_mobileNone">
        <h2>Our guiding principles</h2>
        <div className="p-2">
          Aligned to our{" "}
          <Link to="/careers">
            <u>Core Values</u>
          </Link>
          , we are intentional in our actions, fostering collaboration, driving innovation, acting with conviction, and
          building a spirited and supportive community within our teams and with our clients.
        </div>
      </div>
    </SectionContent>
  </section>
);
