import { boardOfDirectors, leadershipTeam, TeamMember } from "../../types/teamMembers";
import { SectionContent } from "../common/sectionContent";
import { TeamMemberCard } from "./teamMemberCard";
import Slider, { Settings } from "react-slick";

export const TeamSection = () => {
  const sliderSettings: Settings = {
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <section className="team-section">
      <SectionContent maxWidthType="section-maxWidth-small">
        <h2>Our Leadership Team</h2>
        <p>
          Our senior leadership group, along with the entire Flueid team, plays a pivotal role in advancing Flueid’s VOT
          vision and upholding its core values. We are driven by a passion to continuously improve, evolve, and innovate
          the real estate life cycle to help our clients deliver the on-demand experience consumers have come to know
          and expect.
        </p>

        <div className="teamMember-grid">
          {leadershipTeam.map((x: TeamMember, i: number) => (
            <TeamMemberCard key={`teamMember-card-${i}`} teamMember={x} />
          ))}
        </div>
        <Slider {...sliderSettings}>
          {leadershipTeam.map((x: TeamMember, i: number) => (
            <TeamMemberCard key={`teamMember-card-${i}`} teamMember={x} />
          ))}
        </Slider>
      </SectionContent>
    </section>
  );
};
