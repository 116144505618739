import { useState } from "react";
import SecurityDetailsRow from "./securityDetailsRow";
import iconBank from "../../images/icons/icon-bankgrade.svg";
import iconCloud from "../../images/icons/icon-cloud.svg";
import iconArrows from "../../images/icons/icon-config.svg";
import iconDataFirst from "../../images/icons/icon-datafirst.svg";
import iconControl from "../../images/icons/icon-data-white.svg";
import { TextLink } from "components/common/textLink";
import { SectionContent } from "components/common/sectionContent";
import iconShield from "../../images/icons/shield-check-light_W.svg";

export interface SecurityDetails {
  title: string;
  description: string;
  img: string;
}

const bankSecurity: SecurityDetails = {
  title: "Bank-Grade Security",
  description: `Our zero-trust architecture for Flueid Decision and Flueid Transact prioritizes the protection of your data and your clients’ data, designed by experts who contributed to Shared Assessments’ standard of information gathering (SIG) for institutional banks and lenders.`,
  img: iconBank,
};

const cloudNativeArch: SecurityDetails = {
  title: "Cloud-Native Architecture",
  description: `Our VOT solutions are 100% architected in cloud-native services, offering scalability, elasticity, resiliency, flexibility, and most importantly, enhanced security, as you grow and evolve.`,
  img: iconCloud,
};

const configSolutions: SecurityDetails = {
  title: "Configurable Solutions",
  description: `Tailor our VOT solutions to fit your unique needs and business objectives, optimizing your systems for a competitive advantage.`,
  img: iconArrows,
};

const apiTokens: SecurityDetails = {
  title: "Token-Based API Integrations",
  description: `Our VOT platform APIs securely exchange data between systems using leading token-based authentication and dedicated KMS encryption management.`,
  img: iconDataFirst,
};

const dataControl: SecurityDetails = {
  title: "Control Your Own Data",
  description: `Gain maximum data control in transit and at rest by inheriting an audited and hardened environment for our Flueid Decision and Flueid Transact platforms, which can be placed behind your ITGCs.`,
  img: iconControl,
};

const fipsCertified: SecurityDetails = {
  title: "FIPS 140-2 Level 3 Certified",
  description:
    "Our Flueid Decision platform leverages the Federal Information Processing Standards (FIPS) 140-2 Security Level 3 certification through AWS KMS HSMs, offering unparalleled cryptographic protection and compliance with rigorous industry standards. This unmatched security framework provides our clients with a distinct competitive edge in a landscape where data protection is paramount.",
  img: iconShield,
};

const details: SecurityDetails[] = [
  bankSecurity,
  cloudNativeArch,
  configSolutions,
  apiTokens,
  dataControl,
  fipsCertified,
];

export const SolutionsSection = () => {
  const [open, setOpen] = useState(bankSecurity);

  return (
    <section className="solutions-section" id="solutions">
      <div className="waves-container">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(241, 248, 255, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(241, 248, 255, 0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(241, 248, 255, 0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(241, 248, 255, 1)" />
          </g>
        </svg>
      </div>
      <SectionContent maxWidthType="section-maxWidth-small" className="solutions-content">
        <h2 className="security-header">We are your partner in security</h2>
        <div className="security-flex">
          <div className="card">
            {details.map((d, i) => (
              <SecurityDetailsRow onClick={() => setOpen(d)} key={i} open={open === d} details={d} />
            ))}
            <TextLink to="/security" color="white" className="my-4">
              Explore our security principles
            </TextLink>
          </div>
          <div className="security-right">
            <div className="aicpa-img"></div>
            <hr className="security-banner__divider" />
            <h3 className="txt-light txt-center" style={{ padding: "0 1rem" }}>
              Aligned with current <br />
              <b>NIST CSF</b> standards
            </h3>
          </div>
        </div>
      </SectionContent>
      <div className="trust-img"></div>
    </section>
  );
};
