import { SectionContent } from "components/common/sectionContent";
import DashedLineGroup, { DashedLine } from "components/controls/dashedLines";

const TrustExpertiseSection = () => {
  return (
    <section className="sec-trust-expertise-section">
      <SectionContent>
        <div className="sec-lifecycle-expertise-grid">
          <div className="dashed-line-container">
            <DashedLineGroup className="sec-trust-expertise-dashed-lines">
              {(visible) => (
                <>
                  <DashedLine color="white" width={214} duration={2.1} delay={0} x={"1vw"} show={visible} />
                  <DashedLine color="white" width={32} duration={2.1} delay={0.3} x={"5vw"} show={visible} />
                  <DashedLine color="white" width={78} duration={1.3} delay={0.6} x={"9vw"} show={visible} />
                  <DashedLine color="white" width={157} duration={0.9} delay={0.8} x={"12vw"} show={visible} />
                </>
              )}
            </DashedLineGroup>
          </div>
          <div></div>
          <div>
            <h3>
              <b>Trust in</b> Our Expertise
            </h3>
            <p className="p-1">
              <b>
                We advised the Shared Assessments group on SIG, specifically scoping cloud security practices into the
                framework for institutional banks and lenders.
              </b>{" "}
              SIG represents the first move to standardize an industry security framework to make sure vendors and their
              products meet a baseline for security, privacy and data handling.
            </p>
          </div>
        </div>
      </SectionContent>
    </section>
  );
};

export default TrustExpertiseSection;
