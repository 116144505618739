import { SectionContent } from "components/common/sectionContent";
// import { forwardRef, ReactElement } from "react";
import { ReactComponent as IconSecure } from "../../images/icons/icon-secure.svg";
import { ReactComponent as IconKey } from "../../images/icons/icon-key.svg";
import { ReactComponent as IconApi } from "../../images/icons/icon-api.svg";
import { ReactComponent as IconPrivate } from "../../images/icons/icon-private.svg";
import { ReactComponent as IconDatabase } from "../../images/icons/icon-data.svg";
import { ReactComponent as IconCentral } from "../../images/icons/icon-central.svg";
import { ReactComponent as IconCloud } from "../../images/icons/icon-cloud-large.svg";
import { SecurityDetailCard } from "./types";
import Transition from "components/controls/transition";
import Visible from "components/controls/visible";
import { ReactComponent as IconBank } from "../../images/icons/icon-bank-green.svg";

// WHY NO WORKY FORWARD REF???!!!! - trying to wrap this card in a <Visible/><Transition/> which requires
// passing a reference to the element in question - but the animation doesn't work with `forwardRef` or below
// passing it directly as a prop
// const ProductDetailCard = forwardRef<HTMLDivElement, SecurityDetailCard>((detail, ref) => (
//     <div className="product-detail-card" ref={ref}>
//         <div className="product-detail-card-dots"/>
//         <div className="product-detail-content">
//             <div>{detail.icon}</div>
//             <p className="p-2 margin-none">{detail.text}</p>
//         </div>
//     </div>
// ))

// ProductDetailCard.displayName = "ProductDetailCard"

// THIS DOESN'T WORK EITHER???!!!!
// type ProductDetailCardProps = {
//   detail: SecurityDetailCard;
//   ref: React.RefObject<HTMLDivElement>
// }
// const ProductDetailCard = ({detail, ref}: ProductDetailCardProps) => (
//   <div className="product-detail-card" ref={ref}>
//     <div className="product-detail-card-dots" />
//     <div className="product-detail-content">
//       <div>{detail.icon}</div>
//       <p className="p-2 margin-none">{detail.text}</p>
//     </div>
//   </div>
// );
const ProductDetailCard = (detail: SecurityDetailCard) => (
  <div className="product-detail-card">
    <div className="product-detail-card-dots" />
    <div className="product-detail-content">
      <div>{detail.icon}</div>
      <p className="margin-none">{detail.text}</p>
    </div>
  </div>
);

const ProductDevelopmentSection = () => {
  const details1: SecurityDetailCard[] = [
    {
      delay: 250,
      animation: "fadeInUp",
      icon: <IconBank />,
      text: "Bank-grade security in a dedicated tenancy architecture.",
    },
    {
      delay: 500,
      animation: "fadeInUp",
      icon: <IconSecure />,
      text: "Enforcement of a zero-trust security on every transaction, including encryption down at the order level with Flueid Decision.",
    },
    {
      delay: 1000,
      animation: "fadeInUp",
      icon: <IconKey />,
      text: "Client-specific key management systems.",
    },
    {
      delay: 1500,
      animation: "fadeInUp",
      icon: <IconApi />,
      text: "Token-based, authenticated, authorized and end-to-end encrypted API calls to secure data in transit.",
    },
  ];

  const details2: SecurityDetailCard[] = [
    {
      delay: 500,
      animation: "fadeInUp",
      icon: <IconPrivate />,
      text: "Your competitive advantages remain private – with you.",
    },
    {
      delay: 1000,
      animation: "fadeInUp",
      icon: <IconDatabase />,
      text: "You control your data, both in transit and at rest with no user accounts having access to the encryption keys.",
    },
    {
      delay: 1500,
      animation: "fadeInUp",
      icon: <IconCentral />,
      text: "One environment per client means there’s no centralized aggregated data store - limiting exposure and allowing data handling and retention practices to match internal policies.",
    },
  ];

  return (
    <section className="sec-prod-dev-section">
      <SectionContent className="relative">
        <IconCloud className="icon-cloud" />
        <h3>
          <b>Security Guides</b> Our Product Development
        </h3>
        <p className="p-1 p-max-width">
          <b>Our customers control what we build for them. </b>
          All our VOT® platforms are 100% architected in cloud-native services to bring you elasticity, flexibility,
          scalability, and resilience. Flueid Decision and Flueid Transact also feature key security principles,
          including:
        </p>
        <div className="product-details-row">
          {details1.map((detail, i) => (
            <Visible key={detail.text}>
              {({ visible, ref }) => (
                <Transition animation={detail.animation} duration={1000} visible={visible} delay={detail.delay}>
                  <div ref={ref} className="flex-1">
                    <ProductDetailCard {...detail} />
                  </div>
                  {/* <ProductDetailCard detail={detail} key={detail.text} ref={ref}/> */}
                </Transition>
              )}
            </Visible>
          ))}
        </div>
        <p className="p-1 p-max-width">
          <b>
            Each of our customers receives a dedicated instance Flueid Decision and/or Flueid Transact within a
            standalone, hardened, and audited environment.
          </b>
          This setup ensures complete data segregation, giving the client full control over their own customer data.
        </p>
        <div className="product-details-row mb-0">
          {details2.map((detail, i) => (
            <Visible key={detail.text}>
              {({ visible, ref }) => (
                <Transition animation={detail.animation} duration={1000} visible={visible} delay={detail.delay}>
                  <div ref={ref} className="flex-1">
                    <ProductDetailCard {...detail} />
                  </div>
                  {/* <ProductDetailCard detail={detail} key={detail.text} ref={ref}/> */}
                </Transition>
              )}
            </Visible>
          ))}
        </div>
      </SectionContent>
    </section>
  );
};

export default ProductDevelopmentSection;
