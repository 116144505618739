import { SectionContent } from "components/common/sectionContent";
import styles from "./styles.module.scss";
import { useState } from "react";
import DashedLineGroup, { DashedLine } from "components/controls/dashedLines";
import { classNames } from "utilities/classNames";
import Popup, { PopupOption } from "components/controls/popup";
import { Button } from "components/controls/button";
import { PopupArrow } from "components/navbar/popupArrow";

type ServiceSlide = {
  name: string;
  header: string;
  description: React.ReactNode;
};

export function ServicesIntroSection() {
  const slides: ServiceSlide[] = [
    {
      name: "VOT",
      header: "What is VOT?",
      description: (
        <>
          <p className={styles.description}>
            Title is critical in securing and facilitating the real estate life cycle, but is often verified manually
            and too late in the process.
          </p>
          <p className={styles.description}>
            VOT addresses this by automating title search and examine with robust data and analytics. It also enhances
            efficiency across the life cycle, providing early title status verification, for improved transparency and
            reliability from lead to closing and throughout servicing and capital markets.
          </p>
        </>
      ),
    },
    { name: "PURCHASE", header: "TBD", description: "TBD" },
    { name: "REFINANCE", header: "TBD", description: "TBD" },
    { name: "HOME EQUITY", header: "TBD", description: "TBD" },
    { name: "LOSS MITIGATION", header: "TBD", description: "TBD" },
    { name: "LOAN MODIFICATION", header: "TBD", description: "TBD" },
  ];
  const [activeSlide, setActiveSlide] = useState<ServiceSlide>(slides[0]);
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <section className={styles.section}>
      <SectionContent className={styles.content}>
        {/* NOTE JB - the "slides" buttons should remain commented out and will be used in future phase. */}
        {/* <div className={classNames(styles.tabs, styles.tabs_desktop)}>
          {slides.map((x) => (
            <button
              key={`tab-${x.name}`}
              className={styles.tab}
              data-active={activeSlide?.name === x.name}
              onClick={() => setActiveSlide(x)}
            >
              {x.name}
            </button>
          ))}
        </div> */}
        {/* <div className={classNames(styles.tabs, styles.tabs_mobile)}>
          <Popup
            className={styles.popup_wrapper}
            open={popupOpen}
            onOpen={() => setPopupOpen(true)}
            onClose={() => setPopupOpen(false)}
            trigger={
              <Button className={styles.popup_button}>
                {activeSlide?.name} &nbsp; {PopupArrow(popupOpen, "black")}
              </Button>
            }
          >
            {slides.map((x) => (
              <PopupOption
                key={`popup-option-${x.name}`}
                onClick={() => {
                  setPopupOpen(false);
                  setActiveSlide(x);
                }}
              >
                {x.name}
              </PopupOption>
            ))}
          </Popup>
        </div> */}
        <div className={styles.slide_content}>
          {activeSlide && (
            <>
              <h2 className={styles.header}>{activeSlide.header}</h2>
              {activeSlide.description}
            </>
          )}
        </div>
      </SectionContent>
      <div className={styles.waves_wrapper}>
        <svg
          className={styles.waves}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(248,248,248,0.7" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(248,248,248,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(248,248,248,0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(248,248,248)" />
          </g>
        </svg>
      </div>
    </section>
  );
}
