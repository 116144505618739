import { SectionContent } from "components/common/sectionContent";
import styles from "./styles.module.scss";
// import blendLogo from "images/logos/logo-blend-bw.png";
import encompassLogo from "images/logos/Encompass.png";
import firstCloseLogo from "images/logos/FirstClose.png";
import reswareLogo from "images/logos/Resware.png";
import softProLogo from "images/logos/SoftPro_80.png";
import { NavLink } from "react-router-dom";

export function IntegrationsSection() {
  return (
    <section className={styles.section} id="integrations-section">
      <SectionContent maxWidthType="section-maxWidth-xsm">
        <article className={styles.card}>
          <div className={styles.card_content}>
            <h3 className="txt-bold margin-top_none">Ready to get started with VOT?</h3>
            <p>
              Our VOT platforms are integrated with some of the industry’s leading technologies. Don’t see your system
              listed? Get started using <b>Flueid Pro</b> and/or integrate with our APIs or
              <b>Flueid Transact</b> platform based on your needs.
            </p>
            <NavLink to="/demo?topic=demo">
              <button className="btn btn-blue flex flex-center btn-learn-more btn-maxwidth ">Get a demo!</button>
            </NavLink>
            <div className={styles.logos}>
              <img className={styles.logo} src={softProLogo} alt="SoftPro logo" />
              <img className={styles.logo} src={encompassLogo} alt="Encompass logo" />
              <img className={styles.logo} src={reswareLogo} alt="Resware logo" />
              <img className={styles.logo} src={firstCloseLogo} alt="FirstClose logo" />
              {/* TODO JB - marketing is undecided if blend logo is misleading or not...keep commented out for now */}
              {/* <img className={styles.logo} src={blendLogo} alt="Blend logo" /> */}
            </div>
          </div>
        </article>
      </SectionContent>
    </section>
  );
}
